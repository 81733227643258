import { API_URL } from "../util/constants";

export const getAllDepartments = async token => {
  const response = await fetch(`${API_URL}/departments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getAllDepartmentsWithCount = async token => {
  const response = await fetch(`${API_URL}/admin/departments/deps_with_count`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};
