import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../api/admin";
import { LoadingScreen } from "../../components/LoadingScreen";
import { UsersTable } from "../../components/UsersTable";
import { AddUserModal } from "../../components/modals/AddUserModal";
import { UserOptionsModal } from "../../components/modals/UserOptionsModal";

export const AdminUsers = () => {
  const user = useSelector(state => state.user);
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["admin_users", user.token],
    async () => {
      return getAllUsers(user.token);
    }
  );

  const [selectedUser, setSelectedUser] = useState(null);

  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);

  const openCreateUserModal = () => setCreateUserModalOpen(true);
  const closeCreateUserModal = () => setCreateUserModalOpen(false);

  if (isError || error) {
    return (
      <div>
        <h1 className="text-lg font-bold">Something went wrong</h1>
        <p className="text-sm">{error.message}</p>
      </div>
    );
  }

  return !isLoading ? (
    <>
      <UserOptionsModal
        isOpen={selectedUser !== null}
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
        refetchUsers={refetch}
      />
      <AddUserModal
        isOpen={isCreateUserModalOpen}
        onClose={closeCreateUserModal}
        refetchUsers={refetch}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Manage your users
          </h1>
          <p className="text-gray-700">
            Most recently updated documents in your organisation
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={openCreateUserModal}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add a new user
          </button>
        </div>
      </div>
      <div className="mt-2">
        {data ? (
          <UsersTable
            openOptionsModal={user => setSelectedUser(user)}
            users={data}
            loading={isLoading}
          />
        ) : (
          <div>No documents</div>
        )}
      </div>
    </>
  ) : (
    <LoadingScreen />
  );
};
