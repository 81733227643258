import { useState } from "react";
import { toast } from "react-toastify";

const ExampleConfig = {
  fields: [
    {
      label: "Change Name",
      input: "text",
    },
    {
      label: "Password",
      input: "password",
    },
  ],
  onSubmit: stuffs => {},
};

export const PropertyModal = ({ fields, onSubmit, saveName = "Save" }) => {
  const [fieldData, setFieldData] = useState();

  if (!fields || !fields.length) return null;

  const submit = () => {
    if (!fieldData || Object.keys(fieldData).length !== fields.length) {
      toast("Please fill out all fields", { type: "error" });

      return;
    }

    onSubmit(fieldData);
  };

  return (
    <div className="fade-in">
      {fields.map((item, key) => (
        <div key={key}>
          <div className="mb-4">
            <div className="block mb-2 font-medium text-gray-900">
              <label htmlFor={item.name}>{item.label} </label>
              <span className="text-gray-400 text-sm">(required)</span>
            </div>
            <input
              id={item.name}
              onChange={e =>
                setFieldData(prev => ({ ...prev, [item.name]: e.target.value }))
              }
              type={item.input ?? "text"}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition-all duration-200 ease-in-out"
              placeholder="Woah cool doc bro >.>"
              required
            />
          </div>
        </div>
      ))}

      <div className="w-full flex">
        <button
          onClick={submit}
          type="button"
          className="!w-full mt-4 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {saveName}
        </button>
      </div>
    </div>
  );
};
