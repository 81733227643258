import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginWithEmail } from "../../api/auth";
import { LoadingScreen } from "../../components/LoadingScreen";
import { setUser } from "../../redux/user";

export const Login = () => {
  const emailRef = useRef(null);
  const passRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector(state => state.user);

  const [loaded, setLoaded] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async () => {
    setError(null);
    setSubmitting(true);
    const email = emailRef.current.value;
    const password = passRef.current.value;

    if (!email || !password || email.length < 5 || password.length < 3) {
      setSubmitting(false);
      setError("Please enter a valid email and password");
      return;
    }

    let user;

    try {
      user = await loginWithEmail({ email, password });
    } catch (e) {
      setSubmitting(false);
      toast.error("Something went wrong");

      return setError("Invalid email or password");
    }

    if (!user || !user.userInfo || !user.userInfo.id) {
      setSubmitting(false);
      return setError("Invalid email or password");
    }

    dispatch(setUser(user));
    localStorage.setItem("token", user.token);

    setSubmitting(false);

    navigate("/dashboard");

    // do the login procedure
  };

  useEffect(() => {
    if (user && user.authed) {
      toast("Redirected to dashboard");

      navigate("/dashboard");
    }

    setLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Log in to DGAdmin
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    ref={emailRef}
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div>
                  <input
                    id="password"
                    name="password"
                    ref={passRef}
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <p className="text-red-500">{error}</p>

              <div>
                <button
                  onClick={onSubmit}
                  type="submit"
                  className={`w-full btn flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  ${submitting && "loading"}
                  
                  `}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingScreen />
  );
};
