import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDepartmentDocs } from "../../api/dashboard";
import { DocumentTable } from "../../components/DocumentTable";
import { LoadingScreen } from "../../components/LoadingScreen";
import { openModal } from "../../redux/modal";

export const YourDocuments = () => {
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isError, error } = useQuery(
    ["your_docs", user.token],
    async () => {
      return getDepartmentDocs(user.token);
    }
  );

  const openDocModal = () => dispatch(openModal());

  return !isLoading ? (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Recent documents in organisation "{user.department.name}"
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Most recently updated documents in your organisation
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={openDocModal}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add a document
          </button>
        </div>
      </div>
      {data && data.documents ? (
        <DocumentTable
          showCreator
          showOrganisation={false}
          documents={data.documents}
          loading={isLoading}
        />
      ) : (
        <div>No documents</div>
      )}
    </>
  ) : (
    <LoadingScreen />
  );
};
