import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadDocument } from "../api/document";
import { getDocType } from "../util/documents";
import { LoadingScreen } from "./LoadingScreen";

export const VersionTable = ({ documents, loading, doc }) => {
  const navigte = useNavigate();
  const user = useSelector(state => state.user);
  const [downloading, setDownloading] = useState(null);

  const downloadDoc = docVersion => {
    // note that we'll need to use a specific version of the document here

    setDownloading(docVersion.id);

    downloadDocument({
      docId: doc.id,
      token: user.token,
      version: docVersion.id,
    })
      .then(async res => {
        if (!res) {
          return toast("Error downloading document.", { type: "error" });
        }

        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${doc.name.split("-")[0]}.${
            doc.DocumentContent.sort((a, b) => a.version > b.version)[0]
              .fileUrl.split(".")
              .slice(-1)[0]
          }`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .finally(() => setDownloading(null));
  };

  return !loading ? (
    documents.length === 0 ? (
      <p className="mt-2 text-sm text-gray-700">No documents found.</p>
    ) : (
      <>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-scroll sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Creator
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Version
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        File Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created At
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {documents.map((document, key) => (
                      <tr key={`listel-${key}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {document.creator ? document.creator.name : "Unknown"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {document.version}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {getDocType(document.fileUrl.split(".").slice(-1)[0])}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment(document.updatedAt).fromNow()}
                        </td>

                        <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 flex flex-col w-32 pr-2">
                          {downloading === document.id ? (
                            <LoadingScreen small />
                          ) : (
                            <>
                              <p
                                onClick={() => downloadDoc(document)}
                                className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                              >
                                Download Document
                              </p>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  ) : (
    <div className="w-full mt-12">
      <LoadingScreen small />
    </div>
  );
};
