import { API_URL } from "../util/constants";

export const getAllUsers = async token => {
  const response = await fetch(`${API_URL}/admin/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const changeName = async (token, userId, name) => {
  const response = await fetch(`${API_URL}/admin/users/change_name`, {
    body: JSON.stringify({ id: userId, name }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const changePassword = async (token, userId, password) => {
  const response = await fetch(`${API_URL}/admin/users/change_password`, {
    body: JSON.stringify({ id: userId, password }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const deleteUser = async (token, userId) => {
  const response = await fetch(`${API_URL}/admin/users`, {
    body: JSON.stringify({ id: userId }),
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getUserDepartment = async (token, userId) => {
  const response = await fetch(`${API_URL}/admin/users/user_dep?id=${userId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    let message;

    try {
      message = await response.json();
    } catch (e) {
      return null;
    }

    return message || null;
  }

  const data = await response.json();
  return data;
};

export const addToDepartment = async (token, userId, departmentId) => {
  const response = await fetch(`${API_URL}/admin/users/department`, {
    body: JSON.stringify({ userId, departmentId }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const createUser = async ({ token, name, email, password }) => {
  const response = await fetch(`${API_URL}/admin/users`, {
    body: JSON.stringify({ name, email, password }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const deleteDepartment = async (token, departmentId) => {
  const response = await fetch(`${API_URL}/admin/departments`, {
    body: JSON.stringify({ id: departmentId }),
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const createDepartment = async ({ token, name }) => {
  const response = await fetch(`${API_URL}/admin/departments`, {
    body: JSON.stringify({ name }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};
