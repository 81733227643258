import {
  BiFile,
  BiHome,
  BiLogOut,
  BiPaperclip,
  BiPaperPlane,
  BiPlus,
  BiUser,
} from "react-icons/bi";
import { MdExpandLess, MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../redux/modal";
import { NewDocModal } from "./modals/NewDocModal";

const logout = () => {
  localStorage.removeItem("token");
  window.location.replace("/");
};

export const DashLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const modalOpen = useSelector(state => state.modals.createModalOpen);

  const getNavItems = user => {
    const baseItems = [
      {
        label: "Home",
        url: "/dashboard/home",
        icon: <BiHome />,
      },
      {
        label: "Your Documents",
        url: "/dashboard/documents/owned",
        icon: <BiPaperclip />,
      },
      {
        label: "Shared Documents",
        url: "/dashboard/documents/shared",
        icon: <BiPaperPlane />,
      },
      {
        label: "Archive",
        url: "/dashboard/archive",
        icon: <BiFile />,
      },
      {
        label: "New Document",
        onClick: () => dispatch(openModal()),
        icon: <BiPlus />,
      },
    ];

    return baseItems;
  };

  const getActionItems = user => {
    const baseItems = [
      {
        label: "My Profile",
        url: "/dashboard/profile",
        icon: <BiUser />,
      },
      {
        label: "Log out",
        onClick: logout,
        icon: <BiLogOut />,
      },
    ];

    if (user.permissions) {
      // baseItems.push({
      //   label: "Admin",
      //   url: "/dashboard/admin",
      //   icon: <MdLock />,
      // });

      // add the item to the start of the array
      baseItems.unshift({
        label: "Manage Users",
        url: "/dashboard/admin/users",
        icon: <MdLock />,
      });

      baseItems.unshift({
        label: "Manage Departments",
        url: "/dashboard/admin/departments",
        icon: <MdLock />,
      });
    }

    return baseItems;
  };

  const backpage = () => navigate(-1);

  return (
    <>
      <NewDocModal
        isOpen={modalOpen}
        onOpen={() => dispatch(openModal())}
        onClose={() => dispatch(closeModal())}
      />
      <div className="flex overflow-x-hidden">
        <div className="w-[300px] min-w-[250px]" />
        <div className="w-[250px] bg-slate-800 text-white h-screen flex flex-col justify-between p-0 fixed">
          <div>
            <p className="text-xl font-black mt-8 mx-4">DGAdmin</p>
            <p className="text-sm tracking-wider mb-8 mx-4 mt-2">
              {user.department.name ?? (
                <span className="text-red-400">No department</span>
              )}
            </p>

            <div className="mt-8 flex flex-col w-full">
              {getNavItems(user).map((item, key) => (
                <p
                  className={`w-full flex justify-start items-center px-4 py-4  cursor-pointer transition duration-100 ease-in-out

              ${
                location.pathname.includes(item.url)
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-slate-800 hover:bg-slate-900"
              }
              
              `}
                  key={key}
                  onClick={
                    item.onClick ? item.onClick : () => navigate(item.url)
                  }
                >
                  <span className="mr-4 text-xl">{item.icon}</span>
                  {item.label}
                </p>
              ))}
            </div>
          </div>

          <div>
            {getActionItems(user).map((item, key) => (
              <p
                className={`w-full flex justify-start items-center px-4 py-4  cursor-pointer transition duration-100 ease-in-out

              ${
                location.pathname.includes(item.url)
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-slate-800 hover:bg-slate-900"
              }
              
              `}
                key={key}
                onClick={item.onClick ? item.onClick : () => navigate(item.url)}
              >
                <span className="mr-4 text-xl">{item.icon}</span>
                {item.label}
              </p>
            ))}
          </div>
        </div>
        <div className="w-full p-8 relative">
          <div className="w-full z-50 h-12 " style={{ zIndex: 9999 }}>
            <div
              className="flex gap-2 items-center text-gray-500 font-bold cursor-pointer group"
              onClick={backpage}
            >
              <MdExpandLess className="-rotate-90 text-xl group-hover:-translate-x-1 transition-transform duration-200 ease-in-out" />{" "}
              Back
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
