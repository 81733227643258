import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "./LoadingScreen";

export const AuthPage = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const user = useSelector(state => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.authed) {
      setLoaded(true);
      return;
    }

    console.log("Unauthorized access");

    navigate("/");
  }, [user]);

  return loaded ? children : <LoadingScreen />;
};
