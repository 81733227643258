import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDashboardDocs } from "../../api/dashboard";
import { DocumentTable } from "../../components/DocumentTable";
import { LoadingScreen } from "../../components/LoadingScreen";

export const DashboardPage = () => {
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery(
    ["dashboard_info", user.token],
    () => getDashboardDocs(user.token)
  );

  useEffect(() => {
    console.log(user);
  }, [user]);

  // This should be like a grid column type thing
  return (
    <>
      <h1 className="text-2xl font-extrabold">
        Welcome back, {user.name.split(" ")[0]}
      </h1>

      <div className="w-full mx-auto mt-7">
        {!isLoading ? (
          data ? (
            <>
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Recent documents in your organisation
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Most recently updated documents in your organisation
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate("/dashboard/documents/owned")}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    View All
                  </button>
                </div>
              </div>
              <DocumentTable
                documents={data.ownedDocuments}
                loading={isLoading}
              />

              <div className="sm:flex sm:items-center mt-8">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Recent documents shared with you
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Most recently updated documents shared with your
                    organisation
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate("/dashboard/documents/shared")}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    View All
                  </button>
                </div>
              </div>
              <DocumentTable
                documents={data.sharedDocuments}
                loading={isLoading}
              />
            </>
          ) : isError ? (
            <div>{error.message}</div>
          ) : (
            <div>No documents found</div>
          )
        ) : (
          <div className="mt-12">
            <LoadingScreen small />
          </div>
        )}
      </div>
    </>
  );
};
