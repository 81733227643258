import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MdExpandLess } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeName, changePassword, deleteUser } from "../../api/admin";
import { ManageOrganisation } from "./ManageUserOrganisation";
import { PropertyModal } from "./PropertyScreen";

export const UserOptionsModal = ({ user, isOpen, onClose, refetchUsers }) => {
  const [page, setPage] = useState("main");

  const currentUser = useSelector(state => state.user);

  if (!user) return null;

  const USER_OPTIONS = [
    {
      label: "Change Name",
      onSelect: () => setPage("name"),
    },

    {
      label: "Change Password",
      onSelect: () => setPage("password"),
    },

    {
      label: "Manage Organisation",
      onSelect: () => setPage("organisation"),
    },
    {
      label: "Delete User",
      onSelect: () => {
        if (window.confirm(`Are you sure you want to delete ${user.name}?`)) {
          deleteUser(currentUser.token, user.id)
            .then(res => {
              if (res === null) {
                toast("Something went wrong", { type: "error" });
                return;
              }
              toast("User deleted", { type: "success" });
              refetchUsers();
              closeModal();
            })
            .catch(e => toast("Something went wrong", { type: "error" }));
        }
      },
    },
  ];

  const closeModal = () => {
    setPage("main");
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          className={"fade-in"}
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto fade-in">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                {page !== "main" && (
                  <div className="w-full z-50 mb-2" style={{ zIndex: 9999 }}>
                    <div
                      className="flex gap-2 items-center text-gray-700 font-bold cursor-pointer group"
                      onClick={() => setPage("main")}
                    >
                      <MdExpandLess className="-rotate-90 text-xl group-hover:-translate-x-1 transition-transform duration-200 ease-in-out" />{" "}
                      Back
                    </div>
                  </div>
                )}

                {page === "main" && (
                  <>
                    <Dialog.Title className="text-xl text-gray-900">
                      Manage <strong>{user.name}</strong>
                    </Dialog.Title>

                    <div className="flex flex-col gap-4 mt-4">
                      {USER_OPTIONS.map(option => (
                        <button
                          onClick={option.onSelect}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          {option.label}
                        </button>
                      ))}
                    </div>
                  </>
                )}

                {page === "name" && (
                  <div>
                    <PropertyModal
                      fields={[
                        {
                          label: "Pick a new name",
                          name: "name_input",
                          input: "text",
                        },
                      ]}
                      onSubmit={val => {
                        if (!val.name_input || val.name_input.length < 2) {
                          toast("Please enter a name, > 1 character", {
                            type: "error",
                          });
                          return;
                        }

                        changeName(currentUser.token, user.id, val.name_input)
                          .catch(e =>
                            toast("Something went wrong", { type: "error" })
                          )
                          .then(() => {
                            refetchUsers();
                            closeModal();
                          });

                        toast("Name changed successfully", { type: "success" });
                      }}
                    />
                  </div>
                )}

                {page === "password" && (
                  <div>
                    <PropertyModal
                      fields={[
                        {
                          label: "Pick a new password",
                          name: "password_input",
                          input: "password",
                        },
                      ]}
                      onSubmit={val => {
                        if (
                          !val.password_input ||
                          val.password_input.length < 4
                        ) {
                          toast("Please enter a password, > 4 characters", {
                            type: "error",
                          });
                          return;
                        }

                        changePassword(
                          currentUser.token,
                          user.id,
                          val.password_input
                        )
                          .catch(e =>
                            toast("Something went wrong", { type: "error" })
                          )
                          .then(() => {
                            refetchUsers();
                            closeModal();
                          });

                        toast("Password changed successfully", {
                          type: "success",
                        });
                      }}
                    />
                  </div>
                )}

                {page === "organisation" && (
                  <ManageOrganisation
                    closeModal={closeModal}
                    user={user}
                    className="fade-in"
                    refetch={refetchUsers}
                  />
                )}

                <div className="mt-8">
                  <button
                    type="button"
                    className={`mt-3 w-full inline-flex justify-center rounded-md border  h-10 border-gray-300 shadow-sm  bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:mt-0 sm:col-start-1 sm:text-sm btn btn-sm`}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
