import { Combobox, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { CgChevronUp } from "react-icons/cg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToDepartment, getUserDepartment } from "../../api/admin";

export const ManageOrganisation = ({
  className,
  closeModal,
  refetch,
  user,
}) => {
  const currentUser = useSelector(state => state.user);
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchQuery,
  } = useQuery(["user_deps", currentUser.token], async () => {
    return getUserDepartment(currentUser.token, user.id);
  });
  const [selected, setSelected] = useState(
    data?.userDepartment ? data.userDepartment.id : null
  );

  const [query, setQuery] = useState("");

  if (!isLoading && !data?.departments)
    return (
      <div>
        <p>
          You currently don't have any departments, please add one to proceed.
        </p>
      </div>
    );

  const departments = data?.departments;
  const filteredDeps = departments
    ? departments.filter(dep =>
        dep.name.toLowerCase().includes(query.toLowerCase())
      )
    : [];

  const onSave = () => {
    addToDepartment(currentUser.token, user.id, selected.id).then(res => {
      if (res === null) {
        toast("Something went wrong", { type: "error" });
        return;
      }
      toast("User added to department", { type: "success" });
      refetchQuery();
      refetch();
      closeModal();
    });
  };

  return (
    <div>
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1 z-10">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md outline-none   sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 outline-none"
              displayValue={dep => (dep ? dep.name : "Select a department")}
              onChange={event => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <CgChevronUp
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg outline-none sm:text-sm">
              {filteredDeps.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredDeps.map(person => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-blue-600"
                            }`}
                          >
                            <BiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>

      <div className="flex w-full mt-4">
        <button
          type="button"
          className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};
