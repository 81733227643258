import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getArchivedDocs } from "../../api/dashboard";
import { DocumentTable } from "../../components/DocumentTable";
import { LoadingScreen } from "../../components/LoadingScreen";

export const ArchivedDocuments = () => {
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery(
    ["archive_docs", user.token],
    async () => {
      return getArchivedDocs(user.token);
    }
  );

  return !isLoading ? (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Archived Documents
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            All documents created by or shared with the department "
            {user.department.name}"
          </p>
        </div>
      </div>
      {data && data.documents ? (
        <DocumentTable
          showCreator
          showOrganisation={true}
          documents={data.documents}
          loading={isLoading}
        />
      ) : (
        <div>No documents</div>
      )}
    </>
  ) : (
    <LoadingScreen />
  );
};
