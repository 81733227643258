import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllDepartments } from "../../api/departments";
import { shareWithDepartment } from "../../api/document";
import { LoadingScreen } from "../LoadingScreen";

export const ShareDocModal = ({
  isOpen,
  onOpen,
  onClose,
  docId,
  refresh,
  loading,
}) => {
  const selectRef = useRef(null);
  const user = useSelector(state => state.user);
  const [uploading, setUploading] = useState(false);
  const { data, isLoading, isError, error, status } = useQuery(
    ["share_doc", user.token],
    () => {
      return getAllDepartments(user.token);
    }
  );

  const shareDoc = () => {
    setUploading(true);
    const dep = selectRef.current.value;

    if (!dep) {
      toast("Please select a department", { type: "error" });
      setUploading(false);
      return;
    }

    shareWithDepartment({ docId, departmentId: dep, token: user.token })
      .then(res => {
        if (res === null) {
          toast("Something went wrong", { type: "error" });
          return;
        }

        if (res.message === "ALREADY_SHARED") {
          toast("Document already shared", { type: "info" });
        } else toast("Document shared", { type: "success" });

        setUploading(false);
        onClose();
      })
      .catch(e => {
        console.log(e);
        toast("Something went wrong", { type: "error" });
      })
      .finally(() => setUploading(false));
  };

  return !loading ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div>
                    <Dialog.Title className="text-xl text-gray-900 font-bold">
                      Share with another department
                    </Dialog.Title>

                    {isLoading ? (
                      <LoadingScreen small />
                    ) : (
                      <div className="mt-4">
                        <label
                          for="countries"
                          class="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Select a department
                        </label>
                        <select
                          ref={selectRef}
                          id="countries"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          {data.departments.map((dep, id) => (
                            <option value={dep.id}>{dep.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border h-10 border-transparent shadow-sm  bg-black-600  text-white hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:col-start-2 sm:text-sm btn btn-sm ${
                      uploading && "loading"
                    }`}
                    onClick={shareDoc}
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className={`mt-3 w-full inline-flex justify-center rounded-md border  h-10 border-gray-300 shadow-sm  bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:mt-0 sm:col-start-1 sm:text-sm btn btn-sm ${
                      uploading && "loading"
                    }`}
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : (
    <LoadingScreen small />
  );
};
