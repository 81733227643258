import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getSelf } from "../../api/dashboard";
import { LoadingScreen } from "../../components/LoadingScreen";

export const Profile = () => {
  const user = useSelector(state => state.user);

  const { data, isLoading, isError, error } = useQuery(
    ["profile", user.token],
    () => {
      return getSelf(user.token);
    }
  );

  if (error || isError) return <div>Something went wrong.</div>;

  return !isLoading && data && data.name ? (
    <div className="flex gap-8 flex-wrap items-center">
      <FaUserCircle className="text-[120px] text-blue-400" />

      <div className="mt-2">
        <div className="flex gap-2 flex-wrap items-center h-min">
          <h1 className="text-2xl font-bold">{data.name} </h1>
        </div>
        <p className="text-black mb-4">{data.email}</p>
        <p className="text-gray-700">
          <strong>Department</strong>{" "}
          {data.Department ? (
            data.Department?.name
          ) : (
            <span className="text-red-400"> No Department</span>
          )}
        </p>
        <p className="text-gray-700">
          Permissions <strong>{data.permissions ? "Admin" : "User"}</strong>
        </p>
        <p>
          <strong>Created</strong> {moment(data.createdAt).fromNow()}
        </p>
        {data.Document && (
          <p>
            Uploaded <strong>{data.Document?.length}</strong> documents
          </p>
        )}
      </div>
    </div>
  ) : (
    <LoadingScreen small className={"mt-12"} />
  );
};
