import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { MdOnlinePrediction } from "react-icons/md";
import { LoadingScreen } from "./LoadingScreen";

export const UsersTable = ({ users, loading, openOptionsModal }) => {
  const [query, setQuery] = useState("");

  const filteredUsers = users.filter(user => {
    return (
      user.name.toLowerCase().includes(query.toLowerCase()) ||
      user.email.toLowerCase().includes(query.toLowerCase())
    );
  });

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  return !loading ? (
    <>
      <input
        onChange={e => setQuery(e.target.value)}
        value={query}
        type={"text"}
        className="mt-8 mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition-all duration-200 ease-in-out"
        placeholder="Search users"
        required
      />

      {filteredUsers.length === 0 ? (
        <p className="mt-2 text-sm text-gray-700">No users found.</p>
      ) : (
        <>
          <div className="mt-0 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-scroll sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Department
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {users.map((user, key) => (
                        <tr key={`listel-${key}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {user.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.email}
                          </td>
                          <td
                            className={clsx(
                              "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                              !user.Department && "text-red-400"
                            )}
                          >
                            {user.Department
                              ? user.Department.name
                              : "No Department"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment(user.createdAt).fromNow()}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() => openOptionsModal(user)}
                              type="button"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              <MdOnlinePrediction />
                              <span className="sr-only">Icon description</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <div className="w-full mt-12">
      <LoadingScreen small />
    </div>
  );
};
