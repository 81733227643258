import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./modal";
import userReducer from "./user";

export const store = configureStore({
  reducer: {
    user: userReducer,
    modals: modalReducer,
  },
});
