import { API_URL } from "../util/constants";

export const loginWithEmail = async ({ email, password }) => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const loginWithToken = async ({ token }) => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const useIsAdmin = async () => {};
