import moment from "moment";
import { useState } from "react";
import { LoadingScreen } from "./LoadingScreen";

export const DepTable = ({ deps, loading, token }) => {
  const [query, setQuery] = useState("");

  if (loading) {
    return <LoadingScreen />;
  }

  const filteredDeps = deps.filter(dep =>
    dep.name.toLowerCase().includes(query.toLowerCase()) ? dep : null
  );

  return !loading ? (
    <>
      <input
        onChange={e => setQuery(e.target.value)}
        value={query}
        type={"text"}
        className="mt-8 mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition-all duration-200 ease-in-out"
        placeholder="Search departments"
        required
      />

      {filteredDeps.length === 0 ? (
        <p className="mt-2 text-sm text-gray-700">No departments found.</p>
      ) : (
        <>
          <div className="mt-0 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-scroll sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Created At
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Members
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Documents
                        </th>

                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Delete
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filteredDeps.map((dep, key) => (
                        <tr key={`listel-${key}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {dep.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment(dep.createdAt).fromNow()}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {dep.membersCount ?? 0}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {dep.documentsCount ?? 0}
                          </td>

                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                  deleteDepartment(token, dep.id);
                                }
                              }}
                              type="button"
                              className="transition-all inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <div className="w-full mt-12">
      <LoadingScreen small />
    </div>
  );
};
