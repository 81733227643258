import { API_URL } from "../util/constants";

export const getDashboardDocs = async token => {
  const response = await fetch(`${API_URL}/user/dashboard`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getArchivedDocs = async token => {
  const response = await fetch(`${API_URL}/documents/archive`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getDepartmentDocs = async token => {
  const response = await fetch(`${API_URL}/documents/depdocs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getSharedDocs = async token => {
  const response = await fetch(`${API_URL}/documents/shareddepdocs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getSelf = async token => {
  const response = await fetch(`${API_URL}/user/self`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};
