export const getDocType = str => {
  if (!str) return "Unknownish";

  switch (str) {
    case "doc":
      return "Word Document";

    case "docx":
      return "Word Document";

    case "png":
      return "Image";

    case "jpg":
      return "Image";
    default:
      return typeof str === "string"
        ? str.charAt(0).toUpperCase() + str.slice(1)
        : "Unknown";
  }
};
