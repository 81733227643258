import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllDepartmentsWithCount } from "../../api/departments";
import { DepTable } from "../../components/DepTable";
import { LoadingScreen } from "../../components/LoadingScreen";
import { AddDepModal } from "../../components/modals/AddDepModal";

export const AdminDepartments = () => {
  const user = useSelector(state => state.user);
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["admin_departments", user.token],
    async () => {
      return getAllDepartmentsWithCount(user.token);
    }
  );

  const [isCreateDepModalOpen, setCreateDepModalOpen] = useState(false);

  const openCreateDepModal = () => setCreateDepModalOpen(true);
  const closeCreateDepModal = () => setCreateDepModalOpen(false);

  if (isError || error) {
    return (
      <div>
        <h1 className="text-lg font-bold">Something went wrong</h1>
        <p className="text-sm">{error.message}</p>
      </div>
    );
  }

  return !isLoading ? (
    <>
      <AddDepModal
        isOpen={isCreateDepModalOpen}
        onClose={closeCreateDepModal}
        refetchDeps={refetch}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Manage your departments
          </h1>
          <p className="text-gray-700">The departments in your organisation</p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={openCreateDepModal}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add a new department
          </button>
        </div>
      </div>
      <div className="mt-2">
        {data ? (
          <DepTable deps={data.departments} loading={isLoading} />
        ) : (
          <div>No documents</div>
        )}
      </div>
    </>
  ) : (
    <LoadingScreen />
  );
};
