import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createModalOpen: false,
};

export const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: state => {
      state.createModalOpen = true;
    },
    closeModal: state => {
      state.createModalOpen = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { closeModal, openModal } = modalSlice.actions;

export default modalSlice.reducer;
