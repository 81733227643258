import { API_URL } from "../util/constants";

export const downloadDocument = async ({ docId, token, version }) => {
  const response = await fetch(
    `${API_URL}/documents/download/${docId}${
      version ? `?version=${version}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  return response;
};

export const getDocumentInfo = async ({ docId, token }) => {
  const response = await fetch(`${API_URL}/documents/${docId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.status.toString().startsWith("2")) {
    if (response.status.toString().startsWith("404"))
      throw "Document not found";

    throw response;
  }

  const data = await response.json();

  return data;
};

export const uploadNewDoc = async ({ docId, token, file, name, notes }) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("name", name);
  formData.append("notes", notes);

  const response = await fetch(`${API_URL}/documents`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const uploadNewDocVersion = async ({ docId, token, file }) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("existingDocId", docId);

  const response = await fetch(`${API_URL}/documents`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  return { sucecss: true };
};

export const shareWithDepartment = async ({ docId, departmentId, token }) => {
  const response = await fetch(`${API_URL}/documents/share`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ documentId: docId, departmentId }),
  });

  if (!response.status.toString().startsWith("2")) {
    throw response;
  }

  try {
    const res = await response.json();
    if (res.message === "ALREADY_SHARED") {
      return { success: false, message: res.message };
    }
  } catch (e) {
    console.log("no response no biggie");
  }

  return { sucecss: true };
};

export const archiveDocument = async ({ docId, token }) => {
  const response = await fetch(`${API_URL}/documents/archive`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id: docId }),
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  return { sucecss: true };
};

export const unarchiveDocument = async ({ docId, token }) => {
  const response = await fetch(`${API_URL}/documents/unarchive`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id: docId }),
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  return { sucecss: true };
};

export const updateNote = async ({ docId, token, note }) => {
  const response = await fetch(`${API_URL}/documents/note`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id: docId, note }),
  });

  if (!response.status.toString().startsWith("2")) {
    return null;
  }

  return { sucecss: true };
};
