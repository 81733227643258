import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createUser } from "../../api/admin";
import { PropertyModal } from "./PropertyScreen";

export const AddUserModal = ({ isOpen, onClose, refetchUsers }) => {
  const currentUser = useSelector(state => state.user);

  const closeModal = () => {
    onClose();
  };

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const addUser = vals => {
    const { name, email, password } = vals;

    if (!name || !email || !password) {
      toast("Please fill in all fields", { type: "error" });
      return;
    }

    if (name.length < 2) {
      toast("Name is too short", { type: "error" });
      return;
    }

    if (password.length < 5) {
      toast("Password is too short", { type: "error" });
      return;
    }

    if (!validateEmail(email)) {
      toast("Email is not valid", { type: "error" });
      return;
    }

    createUser({
      token: currentUser.token,
      name,
      email,
      password,
    })
      .then(res => {
        if (res === null) {
          toast("Something went wrong", { type: "error" });
          return;
        }

        toast("User created", { type: "success" });
        refetchUsers();
        closeModal();
      })
      .catch(e => toast("Something went wrong", { type: "error" }));
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          className={"fade-in"}
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto fade-in">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <Dialog.Title>
                  <p className="text-lg text-gray-800">Add new user</p>
                </Dialog.Title>
                <div className="my-4" />

                <PropertyModal
                  saveName="Add User"
                  onSubmit={addUser}
                  fields={[
                    {
                      label: "Name",
                      name: "name",
                      input: "text",
                    },

                    {
                      label: "Email",
                      name: "email",
                      input: "email",
                    },
                    {
                      label: "Password",
                      name: "password",
                      input: "password",
                    },
                  ]}
                />

                <div className="mt-2">
                  <button
                    type="button"
                    className={`mt-3 w-full inline-flex justify-center rounded-md border  h-10 border-gray-300 shadow-sm  bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:mt-0 sm:col-start-1 sm:text-sm btn btn-sm`}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
