import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { loginWithToken } from "./api/auth";
import { AuthPage } from "./components/AuthPage";
import { DashLayout } from "./components/DashLayout";
import { LoadingScreen } from "./components/LoadingScreen";
import { Login } from "./pages/auth/login";
import { DashboardPage } from "./pages/dashboard";
import { AdminDepartments } from "./pages/dashboard/admin_departments";
import { AdminUsers } from "./pages/dashboard/admin_users";
import { ArchivedDocuments } from "./pages/dashboard/archive";
import { Profile } from "./pages/dashboard/profile";
import { SharedDocuments } from "./pages/dashboard/shareddocuments";
import { YourDocuments } from "./pages/dashboard/yourdocuments";
import { SingleDocumentPage } from "./pages/document";
import { setUser } from "./redux/user";

function App() {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("No token found");
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }

      setLoaded(true);

      return;
    }

    // try to log the user in with the token
    // if it fails remove and reset to /

    loginWithToken({ token })
      .then(user => {
        if (!user) {
          console.log("invalid token");
          localStorage.removeItem("token");
          window.location.reload();

          return;
        }

        dispatch(setUser(user));
        setLoaded(true);
      })
      .catch(e => {
        console.log(e);
        toast("Something went wrong", { type: "error" });

        setTimeout(() => {
          console.log("invalid token");
          localStorage.removeItem("token");
          window.location.reload();

          return;
        }, 2000);
      });
  }, []);

  return loaded ? (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />

          <Route
            path={"dashboard"}
            element={
              <AuthPage>
                <DashLayout />
              </AuthPage>
            }
          >
            <Route index element={<Navigate to={"home"} />} />
            <Route path="home" element={<DashboardPage />} />
            <Route path="document/:id" element={<SingleDocumentPage />} />
            <Route path={"documents/owned"} element={<YourDocuments />} />
            <Route path={"documents/shared"} element={<SharedDocuments />} />
            <Route path={"archive"} element={<ArchivedDocuments />} />
            <Route path={"profile"} element={<Profile />} />
            <Route path={"admin/users"} element={<AdminUsers />} />
            <Route path={"admin/departments"} element={<AdminDepartments />} />

            <Route path="*" element={<div>Page not found</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  ) : (
    <LoadingScreen />
  );
}

export default App;
