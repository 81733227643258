import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useRef, useState } from "react";
import {
  BiDockBottom,
  BiDownload,
  BiPaperPlane,
  BiPencil,
  BiPlusCircle,
} from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import { FcDocument } from "react-icons/fc";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  archiveDocument,
  downloadDocument,
  getDocumentInfo,
  unarchiveDocument,
  updateNote,
} from "../../api/document";
import { LoadingScreen } from "../../components/LoadingScreen";
import { VersionTable } from "../../components/VersionTable";
import { NewVersionModal } from "../../components/modals/NewVersionModal";
import { ShareDocModal } from "../../components/modals/ShareDocModal";
import { getDocType } from "../../util/documents";

export const SingleDocumentPage = () => {
  const { id } = useParams();
  const user = useSelector(state => state.user);
  const noteRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const toastIssued = useRef(false);

  const [downloading, setDownloading] = useState(false);

  const [noteUpdate, setNoteUpdate] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const openVersionModal = () => setVersionModalOpen(true);
  const closeVersionModal = () => setVersionModalOpen(false);

  const openShareModal = () => setShareModalOpen(true);
  const closeShareModal = () => setShareModalOpen(false);

  const refreshResults = () => setRefresh(!refresh);

  const { data, isLoading, isError, error, status } = useQuery(
    ["single_doc", id, user.token, refresh],
    async () => {
      return getDocumentInfo({ docId: id, token: user.token });
    }
  );

  const updateNoteToNew = () => {
    setUpdating(true);

    const note = noteRef.current.value;

    updateNote({ docId: id, token: user.token, note })
      .then(res => {
        if (res === null) {
          toast("Something went wrong", { type: "error" });
          return;
        }

        setNoteUpdate(false);
        toast("Note updated!", { type: "success" });
        refreshResults();
      })
      .finally(() => setUpdating(false));
  };

  const archiveDoc = () => {
    archiveDocument({ docId: id, token: user.token }).then(res => {
      if (res === null) {
        toast("Something went wrong", { type: "error" });
        return;
      }

      toast("Document archived", { type: "success" });
      refreshResults();
    });
  };

  const unarchiveDoc = () => {
    unarchiveDocument({ docId: id, token: user.token }).then(res => {
      if (res === null) {
        toast("Something went wrong", { type: "error" });
        return;
      }

      toast("Document unarchived", { type: "success" });
      refreshResults();
    });
  };

  const downloadDoc = dgdoc => {
    setDownloading(true);
    downloadDocument({ docId: dgdoc.id, token: user.token })
      .then(async res => {
        if (!res) {
          return toast("Error downloading document.", { type: "error" });
        }

        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${dgdoc.name.split("-")[0]}.${
            dgdoc.DocumentContent.sort((a, b) => a.version > b.version)[0]
              .fileUrl.split(".")
              .slice(-1)[0]
          }`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .finally(() => setDownloading(false));
  };

  if (isLoading) return <LoadingScreen className={"mt-12"} small />;

  if (isError) {
    if (error === "Document not found") {
      if (!toastIssued.current) toast("Document not found", { type: "error" });

      toastIssued.current = true;

      return <Navigate to={"/dashboard/home"} />;
    }

    return (
      <div>
        {typeof error === "string"
          ? error
          : "Something went wrong, please report this issue"}
      </div>
    );
  }

  return !isLoading && !isError && data && data.name ? (
    <>
      <ShareDocModal
        isOpen={shareModalOpen}
        onOpen={openShareModal}
        onClose={closeShareModal}
        docId={id}
        refresh={refreshResults}
      />

      <NewVersionModal
        isOpen={versionModalOpen}
        onOpen={openVersionModal}
        onClose={closeVersionModal}
        docId={id}
        refresh={refreshResults}
      />

      <div className="flex gap-8 flex-wrap">
        <FcDocument className="text-[120px]" />

        <div className="mt-2">
          <div className="flex gap-2 flex-wrap items-center h-min">
            <h1 className="text-2xl font-bold">{data.name.split("-")[0]} </h1>
            {data.archived ? (
              <div className="badge badge-error badge-outline rounded-lg w-[105px]">
                Archived
              </div>
            ) : (
              <div className="badge badge-info badge-outline rounded-lg w-[105px]">
                Not Archived
              </div>
            )}
          </div>
          <p className="">
            <strong>Last updated</strong> {moment(data.updatedAt).fromNow()}
          </p>
          <p className="">
            <strong>Created</strong> {moment(data.createdAt).calendar()}
          </p>

          <p className="font-bold">
            {getDocType(
              data.DocumentContent.sort((a, b) => a.version > b.version)[0]
                .fileUrl.split(".")
                .slice(-1)[0]
            )}{" "}
            File
          </p>
        </div>
        <div className="flex flex-wrap md:ml-5 gap-2 max-w-lg mt-2">
          <button
            className="btn gap-2 justify-between w-[250px] btn-outline"
            onClick={openVersionModal}
          >
            Upload new version
            <BiPlusCircle className="text-lg" />
          </button>
          <button
            className={`btn gap-2 justify-between w-[250px] btn-outline`}
            onClick={() => downloadDoc(data)}
          >
            Download Document
            {downloading ? (
              <CgSpinner className="text-lg animate-spin" />
            ) : (
              <BiDownload className="text-lg" />
            )}
          </button>
          <div
            className={`${
              data.owner.id !== user.department.id && "cursor-not-allowed"
            }`}
          >
            <button
              onClick={data.archived ? unarchiveDoc : archiveDoc}
              className={`btn gap-2 justify-between w-[250px] btn-outline ${
                data.owner.id !== user.department.id && "btn-disabled"
              }`}
            >
              {data.archived ? "Una" : "A"}rchive Document
              <BiDockBottom className="text-lg" />
            </button>
          </div>

          <div
            className={`${
              data.owner.id !== user.department.id && "cursor-not-allowed"
            }`}
          >
            <button
              className={`btn gap-2 justify-between w-[250px] btn-outline ${
                data.owner.id !== user.department.id && "btn-disabled"
              }`}
              onClick={openShareModal}
            >
              Share Document
              <BiPaperPlane className="text-lg" />
            </button>
          </div>
        </div>
      </div>

      <div className="md:ml-5 mt-12">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Notes</h1>
          <p className="mt-2 text-sm text-gray-700">Notes on this document</p>
        </div>
        {noteUpdate ? (
          <div className="mt-4">
            <textarea
              ref={noteRef}
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-4 transition-all duration-200 ease-in-out"
              placeholder="Write something new"
            ></textarea>
            <div className="max-w-[140px]">
              <button
                onClick={updateNoteToNew}
                type="button"
                className={`w-full inline-flex justify-center border h-10 border-transparent shadow-sm px-8 rounded-lg  bg-black-600  text-white hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500 sm:col-start-2 sm:text-sm btn btn-sm ${
                  updating && "loading"
                }`}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4 rounded-lg border-2 broder mt-4 flex items-center gap-4">
            <button
              className="btn btn-circle btn-outline btn-sm"
              onClick={() => setNoteUpdate(true)}
            >
              <BiPencil />
            </button>
            <p className="text-lg">
              {data.note ? data.note : "No notes, add one!"}
            </p>
          </div>
        )}
      </div>

      <div className="md:ml-5 mt-12">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Other versions of this document
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Old versions of this document
          </p>
        </div>

        <VersionTable
          documents={data.DocumentContent}
          loading={isLoading}
          doc={data}
        />
      </div>
    </>
  ) : (
    <div>Somethign went wrong</div>
  );
};
