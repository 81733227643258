import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  name: null,
  email: null,
  permissions: null,
  authed: false,
  department: {
    name: null,
    id: null,
  },
};

export const userSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      const { userInfo: user } = payload;

      state.token = payload.token;
      state.name = user.name;
      state.id = user.id;
      state.email = user.email;
      state.authed = true;
      state.permissions = user.permissions;

      if (user.department) {
        state.department.name = user.department;
        state.department.id = user.departmentId;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
